import { AllPatientsResponseDTO, Details } from '../../dto/patient/patient';
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Button, SxProps, Theme, Tooltip } from '@mui/material';
import { DataGrid, GridColDef, GridSortModel } from '@mui/x-data-grid';
import { localStorageLiterals, qwltMessages } from '../../common/Constants';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { AsyncState } from '../../hooks/use-async';
import { PATIENT_DETAILS } from '../../router';
import { PaginationModel } from '../../containers/patient/PatientList';
import { useNavigate } from 'react-router';
import { useState } from 'react';

const patientListContainer: SxProps<Theme> = {
  '& .custom-header': {
    fontFamily: 'ArchivoExtraLight',
    fontSize: 15,
    fontWeight: 'bold',
    letterSpacing: '1.5px',
    color: '#8C8CA1',
  },
  fontFamily: 'ArchivoExtraLight',
  fontSize: 15,
  fontWeight: 700,
  letterSpacing: '1.5px',
};

const outerDialogBox: SxProps<Theme> = {
  height: '65vh',
  width: '100%',
  display: 'flex',
};

const viewBox: SxProps<Theme> = {
  textTransform: 'none',
  fontFamily: 'ArchivoExtraLight',
  fontSize: 15,
  fontWeight: 700,
  letterSpacing: '1.5px',
  cursor: 'pointer',
  backgroundColor: 'transparent',
  border: 'none',
  color: '#8C8CA1',
  '&:hover': {
    color: 'black',
  },
  '&:active': {
    color: 'black',
  },
};

const viewSelectedBox: SxProps<Theme> = {
  textTransform: 'none',
  fontFamily: 'ArchivoExtraLight',
  fontSize: 15,
  fontWeight: 700,
  letterSpacing: '1.5px',
  cursor: 'pointer',
  backgroundColor: 'transparent',
  border: 'none',
  color: 'black',
  '&:hover': {
    color: 'black',
  },
  '&:active': {
    color: 'black',
  },
};

const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: '#',
    width: 50,
    headerClassName: 'custom-header',
    sortable: false,
    disableColumnMenu: false,
  },
  {
    field: 'externalUserId',
    headerName: 'RT-ID',
    width: 200,
    headerClassName: 'custom-header',
    sortable: false,
    disableColumnMenu: false,
  },
  {
    field: 'patient',
    headerName: 'Patient',
    flex: 1,
    headerClassName: 'custom-header',
    renderCell: (params) => (
      <div style={{ display: 'flex', padding: '5px', borderRadius: '5px', alignItems: 'center' }}>
        <div>
          {!params.row.isSelected ? <span style={{ color: '#8C8CA1' }}>{params.row.patient}</span> : params.row.patient}
        </div>
        {!params.row.isPhoneNumberVerified && (
          <div
            style={{
              display: 'flex',
              backgroundColor: 'yellow',
              borderRadius: '50%',
              width: '20px',
              height: '20px',
              justifyContent: 'center',
              alignItems: 'center',
              marginRight: '5px',
              marginLeft: '8px',
            }}
          >
            <div
              style={{
                color: 'black',
                fontSize: '14px',
                fontWeight: 'bold',
              }}
            >
              !
            </div>
          </div>
        )}
        <div
          style={{
            display: 'flex',
            backgroundColor: '#ECF1F4',
            marginLeft: '8px',
            borderRadius: '5px',
            padding: '2px',
            fontFamily: 'ArchivoExtraLight',
            fontSize: '12px',
            color: '#8C8CA1',
          }}
        >
          {params.row.age !== 0 && <div>{params.row.age},</div>}
          {params.row.gender && <div>{params.row.gender}</div>}
        </div>
      </div>
    ),
  },
  // {
  //   field: 'patientId',
  //   headerName: 'Patient ID',
  //   flex: 1,
  //   headerClassName: 'custom-header',
  //   renderCell: (params) => (
  //     <Tooltip title={params.value}>
  //       <div>{params.value}</div>
  //     </Tooltip>
  //   ),
  // },
  {
    field: 'conditions',
    headerName: 'Conditions',
    flex: 1,
    headerClassName: 'custom-header',
    sortable: false,
    disableColumnMenu: false,
    renderCell: (params: any) => {
      return (
        <Tooltip title={params.row.fullAbbreviation}>
          <span className='table-cell-trucate'>
            {!params.row.isSelected ? (
              <span style={{ color: '#8C8CA1' }}>{params.row.abbreviation}</span>
            ) : (
              params.row.abbreviation
            )}
          </span>
        </Tooltip>
      );
    },
  },
  {
    field: 'registeredOn',
    headerName: 'Registered on',
    width: 300,
    headerClassName: 'custom-header',
    sortComparator: (v1, v2) => dateComparator(v1.toString(), v2.toString()),
    renderCell: (params: any) => {
      return (
        <div>
          {!params.row.isSelected ? (
            <span style={{ color: '#8C8CA1' }}>{params.row.registeredOn}</span>
          ) : (
            params.row.registeredOn
          )}
        </div>
      );
    },
  },
  {
    field: 'View',
    headerName: 'View',
    headerClassName: 'custom-header',
    width: 150,
    sortable: false,
    disableColumnMenu: false,
    renderCell: (params: any) => {
      const navigate = useNavigate();
      return (
        <Button
          onClick={() => {
            const localPatientDetails: Details = {
              id: params.row.id,
              patient: params.row.patient,
              age: params.row.age,
              gender: params.row.gender,
              registeredOn: params.row.registeredOn,
              userConditions: params.row.conditions,
              phoneNumber: params.row.phoneNumber,
              email: params.row.email,
              userId: params.row.userId,
            };
            localStorage.setItem(localStorageLiterals.patientDetails, JSON.stringify(localPatientDetails));
            navigate(PATIENT_DETAILS);
          }}
          sx={params.row.isSelected ? viewSelectedBox : viewBox}
          endIcon={<ArrowForwardIcon />}
        >
          {qwltMessages.view}
        </Button>
      );
    },
  },
];

const formatDateForSorting = (dateString: string): string => {
  const date = new Date(dateString);
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const day = String(date.getUTCDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
};

const dateComparator = (v1: string, v2: string): number => {
  const date1 = formatDateForSorting(v1);
  const date2 = formatDateForSorting(v2);

  if (date1 < date2) {
    return -1;
  }
  if (date1 > date2) {
    return 1;
  }
  return 0;
};

export type PatientListBoxProps = {
  patientList: AsyncState<AllPatientsResponseDTO>;
  paginationModel: PaginationModel;
  sortValue?: GridSortModel;
  onSortChange?: (sortValue: GridSortModel) => void;
  onPaginationModelChange(paginationModel: PaginationModel): void;
};

export const PatientListBox: React.FC<PatientListBoxProps> = ({
  patientList,
  paginationModel,
  sortValue,
  onSortChange,
  onPaginationModelChange,
}) => {
  // TODO: move this method to utils
  const calculateAgeFromISODate = (isoDateString: string): number => {
    const birthDate = new Date(isoDateString); // Parsing ISO date
    const today = new Date(); // Current date

    // Calculate age
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  };

  const getAbbreviatedMonth = (dateString: string): string => {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = { month: 'short' };
    return date.toLocaleDateString('en-US', options).split(' ')[0];
  };

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month: string = getAbbreviatedMonth(dateString);
    const year = date.getUTCFullYear();

    return `${day} ${month} ${year}`;
  };

  const rows = patientList.result?.data?.users.map((item, index) => {
    const abbreviatedConditions = item.userConditions.map((cond) => cond.conditions.abbreviation);
    const conditionsAbbreviation = abbreviatedConditions.slice(0, 5).join(', ');
    const moreConditions = item.userConditions.length > 5 ? '...' : '';
    const patientName = item.lastName ? `${item.firstName} ${item.lastName}` : item.firstName;
    const isSelected = item.status === 'INACTIVE' ? false : true;

    return {
      id: item.rowNumber,
      patient: patientName,
      isPhoneNumberVerified: 'NA',
      age: calculateAgeFromISODate(item.dob),
      gender: item.gender,
      patientId: item.userId,
      abbreviation: conditionsAbbreviation + moreConditions,
      registeredOn: formatDate(item.registeredOn),
      conditions: item.userConditions,
      phoneNumber: item.phoneNumber,
      email: item.email,
      userId: item.userId,
      fullAbbreviation: abbreviatedConditions.join(', '),
      isSelected: isSelected,
      externalUserId: item.externalUserId,
    };
  });

  return (
    <Box sx={outerDialogBox}>
      <DataGrid
        loading={patientList.isLoading}
        rows={rows ?? []}
        rowCount={patientList.result?.data?.totalResults ?? 0}
        columns={columns}
        paginationMode='server'
        pageSizeOptions={[25, 50]}
        sortModel={sortValue}
        onSortModelChange={(newSortModel) => {
          if (onSortChange) {
            onSortChange(newSortModel);
          }
        }}
        paginationModel={paginationModel}
        onPaginationModelChange={onPaginationModelChange}
        checkboxSelection
        getRowId={(row) => row.id}
        getRowClassName={(params) => (params.row.isSelected ? 'selected-row' : '')}
        sx={{
          ...patientListContainer,
          '.MuiDataGrid-row': {
            cursor: 'pointer',
          },
          '.MuiDataGrid-checkboxInput': {
            '&.Mui-checked': {
              color: '#31D0AA',
            },
          },
          '.selected-row': {
            backgroundColor: '#efeffd',
          },
        }}
      />
    </Box>
  );
};
